import { makeAutoObservable, toJS, runInAction, reaction } from "mobx";
import { Store } from "./Store";
import { ApiClient } from "~libs/api";
import { IOpenNodeTaxonomyTerm, IOpenNodePost } from "~models/OpenSearch";
import { ApiResponse } from "apisauce";
import FileSaver from "file-saver";

export const EXCEL_ALLOWED_NUMBER_OF_DOCUMENTS = 2000;
export const DEFAULT_PAGE_SIZE = 6;
export const COMPRESSED_PAGE_SIZE = 12;

export type AutocompleteResultType = IOpenNodeTaxonomyTerm | IOpenNodePost;

export const layouts = ["NORMAL", "COMPRESSED"];

export class SearchStore {
  constructor(private rootStore: Store, private client: ApiClient, private authenticatedClient: ApiClient) {
    makeAutoObservable(this);
    reaction(
      () => this.textQuery.length,
      () => {
        if (!this.textQuery.length && this.autocompleteResults.length) {
          this.docResults = [];
          this.autocompleteResults = [];
          this.suggestions = [];
          this.latestSuggestions = [];
          this.categorizedAutocompleteResults = {
            document_documents: [],
            document_keywords: [],
            document_topics: [],
            document_regions: [],
            document_countries: [],
            document_languages: [],
            document_publishers: [],
            document_authors: [],
            document_content_types: [],
            article: [],
            document: [],
            toolkit: [],
            internal_document: []
          };
        }
      }
    );
    reaction(
      () => this.currentPage,
      () => {
        const element = document.getElementById("search-results-container");

        if (!element || document.documentElement.scrollTop < element.offsetTop) return;

        element.scrollIntoView({ behavior: "smooth" });
      }
    );
  }

  // Pagination variables
  numberOfDocumentResults: number = 0;

  numberOfPages: number = 1;

  currentPage: number = 1;

  // Search variables
  documentResults: IOpenNodePost[] = [];

  sortedResults: IOpenNodePost[] = [];

  autocompleteResults: Array<AutocompleteResultType> = [];

  docResults: IOpenNodePost[] = [];

  suggestions: string[] = [];

  latestSuggestions: string[] = [];

  hasTermResults: boolean = false;

  isSearching: boolean = false;

  categorizedAutocompleteResults: any = {
    document_documents: [],
    document_keywords: [],
    document_topics: [],
    document_regions: [],
    document_countries: [],
    document_languages: [],
    document_publishers: [],
    document_authors: [],
    document_content_types: [],
    article: [],
    document: [],
    toolkit: [],
    internal_document: []
  };

  taxonomies: any = [];

  chunkedDocumentResults: IOpenNodePost[][] = [];

  textQuery: string = "";

  savedTextQuery: string = "";

  autocompleteParams: string = "";

  postBody: any = {};

  layout: string = "NORMAL";

  splitDocumentResults = (results: IOpenNodePost[], chunkSize: number = DEFAULT_PAGE_SIZE) => {
    if (results && results.length > chunkSize) {
      const tempResultsArray = [];
      for (let i = 0; i < results.length; i += chunkSize) {
        const chunk = results.slice(i, i + chunkSize);
        tempResultsArray.push(chunk);
      }
      this.chunkedDocumentResults = tempResultsArray;
      this.numberOfPages = tempResultsArray.length;
    } else {
      this.numberOfPages = 1;
      this.chunkedDocumentResults = [results || []];
    }
  };

  splitTermResults = () => {
    if (this.autocompleteResults?.length) {
      const categorizedResults = {
        document_documents: [],
        document_keywords: [],
        document_topics: [],
        document_regions: [],
        document_countries: [],
        document_languages: [],
        document_publishers: [],
        document_authors: [],
        document_content_types: [],
        article: [],
        document: [],
        toolkit: [],
        internal_document: []
      };
      const rightColumnTypes = {
        article: [],
        document: [],
        toolkit: [],
        internal_document: []
      };

      this.autocompleteResults.forEach(res => {
        if (Object.keys(this.categorizedAutocompleteResults).includes(res?.type)) {
          categorizedResults[res?.type].push(res);
        } else if (Object.keys(rightColumnTypes).includes(res?.type)) {
          categorizedResults[res?.type].push(res);
        }
      });

      this.categorizedAutocompleteResults = categorizedResults;

      this.hasTermResults = true;
    } else {
      this.hasTermResults = false;
    }
  };

  setTextQuery = async value => {
    this.textQuery = value;
  };

  exportExcel = async () => {
    if (!this.postBody) this.generateQueryParams();

    const requestBody = JSON.stringify(this.postBody);

    this.isSearching = true;

    let response: ApiResponse<any>;

    if (this.rootStore.userStore.currentUser && this.rootStore.userStore.isStaffUser) {
      response = await this.authenticatedClient.exportExcel(requestBody);
    } else {
      response = await this.client.exportExcel(requestBody);
    }

    runInAction(() => {
      if (response.ok && !!response.data) {
        FileSaver(response.data, "Search Result");
      }
      this.isSearching = false;
    });
  };

  exportAnalytics = async () => {
    if (!this.postBody) this.generateQueryParams();

    const requestBody = JSON.stringify(this.postBody);

    this.isSearching = true;

    let response: ApiResponse<any>;

    if (this.rootStore.userStore.currentUser && this.rootStore.userStore.isStaffUser) {
      response = await this.authenticatedClient.exportAnalytics(requestBody);
    } else {
      response = await this.client.exportAnalytics(requestBody);
    }

    runInAction(() => {
      if (response.ok && !!response.data) {
        FileSaver(response.data, "Analytics Search Result");
      }
      this.isSearching = false;
    });
  };

  getCollectionFiles = async (id: string) => {
    if (this.isSearching) return;

    this.isSearching = true;

    const isAuthenticated = this.rootStore.userStore.currentUser;

    const response = isAuthenticated ? await this.authenticatedClient.getCollectionFiles(id) : await this.client.getCollectionFiles(id);

    runInAction(() => {
      if (response.ok && !!response.data) {
        this.isSearching = false;
        return response.data;
      }
    });
  };

  getCollectionExcel = async (id: string, title: string) => {
    if (this.isSearching) return;

    this.isSearching = true;

    const isAuthenticated = this.rootStore.userStore.currentUser;

    const response = isAuthenticated ? await this.authenticatedClient.getCollectionExcel(id) : await this.client.getCollectionExcel(id);

    runInAction(() => {
      if (response.ok && !!response.data) {
        FileSaver(response.data, title);
      }
      this.isSearching = false;
    });
  };

  getDocuments = async (body?, reset?, inf?, tax?) => {
    if (!body) this.generateQueryParams();
    const postBody = body || this.postBody;
    if (reset) {
      postBody.page = 1;
      this.currentPage = 1;
    } else if (inf) {
      postBody.page = -1;
    } else {
      postBody.page = Math.ceil(this.currentPage / this.getPageSize());
    }
    if (this.rootStore.filterStore.isCollectionSearch) postBody.collection = true;
    this.isSearching = true;
    let response;
    if (this.rootStore.userStore.currentUser) {
      response = this.rootStore.filterStore.isCollectionSearch ? await this.authenticatedClient.getCollectionDocuments(this.rootStore.filterStore.collectionId!, JSON.stringify(postBody)) : await this.authenticatedClient.getDocuments(JSON.stringify(postBody));
    } else {
      response = this.rootStore.filterStore.isCollectionSearch ? await this.client.getCollectionDocuments(this.rootStore.filterStore.collectionId!, JSON.stringify(postBody)) : await this.client.getDocuments(JSON.stringify(postBody));
    }
    runInAction(() => {
      if (response.ok) {
        if (this.currentPage > 1) {
          this.documentResults = this.documentResults.concat(response?.data?.documents);
        } else {
          this.documentResults = response?.data?.documents;
        }
        this.taxonomies = response?.data?.taxonomies;
        this.numberOfDocumentResults = response?.data?.count || response?.data?.documents?.length;
        this.rootStore.filterStore.setFilter(response?.data?.taxonomies, response?.data?.count, null, tax);
        this.splitDocumentResults(this.documentResults, this.getPageSize());
        this.isSearching = false;
      } else {
        this.isSearching = false;
        console.error(response);
      }
    });
  };

  autocomplete = async () => {
    if (!this.textQuery) return;
    this.generateAutocompleteParams();
    let response;
    if (this.rootStore.userStore.currentUser && this.rootStore.userStore.isStaffUser) {
      response = await this.authenticatedClient.autocomplete(this.autocompleteParams);
    } else {
      response = await this.client.autocomplete(this.autocompleteParams);
    }
    if (response.ok) {
      const docs: IOpenNodePost = response.data?.docTerms?.entities?.all;

      if (docs) {
        this.docResults = Object.values(docs).sort((a: IOpenNodePost, b: IOpenNodePost) => a?.score - b?.score) || [];
        this.autocompleteResults = response?.data?.terms?.concat(this.docResults);
        this.suggestions = response?.data?.suggestions;
        this.latestSuggestions = this.suggestions?.length ? this.latestSuggestions : [];
      }

      this.splitTermResults();
    } else {
      console.error(response);
    }
  };

  setPage = page => {
    this.currentPage = page;
    if (page * this.getPageSize() >= (this.documentResults && this.documentResults.length) && (this.documentResults && this.documentResults.length) < this.numberOfDocumentResults) {
      this.getDocuments();
    }
  };

  generateQueryParams = () => {
    this.postBody = {};

    const { topics, authors, publishers, contentTypes, countries, languages, keywords, regions, publicationYears, prioritize, publicIndex, privateIndex, selectedResourceType } = this.rootStore.filterStore;

    this.postBody["indices"] = [...(publicIndex ? ["public_search"] : []), ...(privateIndex ? ["internal_search"] : [])];

    this.postBody["user_search"] = 1;

    this.postBody["sort_by"] = this.rootStore.filterStore?.selectedSortingKey;

    this.postBody["language"] = this.rootStore.languageStore?.currentLanguage?.slug || this.rootStore.languageStore.getUrlLanguageSlug() || "en";

    const noIndexSelected = !publicIndex && !privateIndex;

    if (selectedResourceType) {
      switch (selectedResourceType) {
        case "resource":
          const documentTypes = [];
          if (publicIndex || noIndexSelected) documentTypes.push("document");
          if (privateIndex) documentTypes.push("internal_document");
          if (documentTypes.length === 0) break;
          this.postBody["post_type"] = documentTypes;
          break;
        case "collection":
          const collectionTypes = ["internal_collection", "featured_collection"];
          if (privateIndex) {
            if (!publicIndex) collectionTypes.pop();
            collectionTypes.push("staff_only_collection");
          }
          if (collectionTypes.length === 0) break;
          this.postBody["post_type"] = collectionTypes;
          break;
        case "article":
          this.postBody["post_type"] = ["article"];
          break;
        case "toolkit":
          this.postBody["post_type"] = ["toolkit"];
          break;
      }
    }

    if (this.textQuery) {
      if (this.rootStore.filterStore?.selectedSortingKey !== "Relevance" && this.textQuery !== this.savedTextQuery) {
        this.postBody["sort_by"] = "Relevance";
        this.rootStore.filterStore.setSortOption("Relevance");
      }
      this.postBody["search"] = this.textQuery;
      this.savedTextQuery = this.textQuery;
    }

    if (keywords) {
      this.postBody["document_keywords"] = [];
      keywords
        .filter(keyword => keyword.selected)
        .forEach(keyword => {
          this.postBody["document_keywords"].push(keyword.slug);
        });
    }

    if (topics) {
      this.postBody["document_topics"] = [];
      topics
        .filter(topic => topic.selected)
        .forEach(topic => {
          this.postBody["document_topics"].push(topic.slug);
        });
    }

    if (countries) {
      this.postBody["document_countries"] = [];
      countries
        .filter(country => country.selected)
        .forEach(country => {
          this.postBody["document_countries"].push(country.slug);
        });
    }

    if (languages) {
      this.postBody["document_languages"] = [];
      languages
        .filter(language => language.selected)
        .forEach(language => {
          this.postBody["document_languages"].push(language.slug);
        });
    }

    if (publishers) {
      this.postBody["document_publishers"] = [];
      publishers
        .filter(publisher => publisher.selected)
        .forEach(publisher => {
          this.postBody["document_publishers"].push(publisher.slug);
        });
    }

    if (authors) {
      this.postBody["document_authors"] = [];
      authors
        .filter(author => author.selected)
        .forEach(author => {
          this.postBody["document_authors"].push(author.slug);
        });
    }

    if (contentTypes) {
      this.postBody["document_content_types"] = [];
      contentTypes
        .filter(contentType => contentType.selected)
        .forEach(contentType => {
          this.postBody["document_content_types"].push(contentType.slug);
        });
    }

    if (regions) {
      this.postBody["document_regions"] = [];
      regions
        .filter(region => region.selected)
        .forEach(region => {
          this.postBody["document_regions"].push(region.slug);
        });
    }

    if (publicationYears && publicationYears.length) {
      if (!this.postBody["publication_year_start"]) {
        this.postBody["publication_year_start"] = [];
      }
      this.postBody["publication_year_start"].push(publicationYears[0].value.toString());
      if (publicationYears.length > 1) {
        if (!this.postBody["publication_year_end"]) {
          this.postBody["publication_year_end"] = [];
        }
        this.postBody["publication_year_end"].push(publicationYears[publicationYears.length - 1].value.toString());
      }
    }

    if (prioritize && prioritize.filter(p => p.selected)) {
      prioritize.forEach(prio => {
        this.postBody[prio.slug] = prio.selected.toString();
      });
    }
  };

  generateAutocompleteParams = () => {
    this.autocompleteParams = `?search=${this.textQuery ? this.textQuery : ""}`;
    if (this.rootStore.languageStore?.currentLanguage?.slug !== "en") this.autocompleteParams = this.autocompleteParams + "&language=" + this.rootStore.languageStore?.currentLanguage?.slug;

    return this.autocompleteParams;
  };

  nullDocumentResults = () => {
    this.documentResults = [];
    this.chunkedDocumentResults = [];
    this.numberOfDocumentResults = 0;
  };

  setSimpleSearch = () => {
    if (this.rootStore.advancedSearchStore.advancedSearchActive) this.rootStore.advancedSearchStore.toggleAdvancedSearchActive();
  };

  toggleLayout = () => {
    this.layout = layouts.find(l => l != this.layout) || "NORMAL";
    this.splitDocumentResults(this.documentResults, this.getPageSize());
  };

  getPageSize = () => (this.layout === "COMPRESSED" ? COMPRESSED_PAGE_SIZE : DEFAULT_PAGE_SIZE);
}
